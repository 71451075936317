<template>
  <div class="d-flex align-items-end flex-wrap">
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <v-select
          v-model="data"
          :options="options"
          :reduce="label => label.value"
          :taggable="args.taggable"
          :create-option="tag => ({ value: tag, label: tag})"
          :placeholder="args.placeholder"
          :class="{'error-border': errors.length}"
      >
      </v-select>
    </div>

  </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
  name: 'InputSelectBasic',
  components: {
    vSelect
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false
    },
    args: {
      type: Object,
      default: () => {
        return {
          taggable: false,
          placeholder: 'Please Select'
        }
      }
    },
    options: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>

