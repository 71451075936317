<template>

          <b-card v-if="lesson" body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail" v-bind:style="{ 'background-image': 'url(' + lesson.cover_image + ')' }">
              <div v-if="lesson.video_file"
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                  <b-link :href="lesson.video_file" target="_blank">
                    <div class="d-flex flex-column text-center align-items-center justify-content-center">
                      <i class="i-Play-Music text-white text-40"/>
                      <span class=" text-20 text-white">Play</span>
                    </div>
                  </b-link>
              </div>
              <div v-else-if="lesson.pdf_file"
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                  <b-link :href="lesson.pdf_file" target="_blank">
                    <div class="d-flex flex-column text-center align-items-center justify-content-center">
                      <i class="i-File-Download text-white text-40"/>
                      <span class=" text-20 text-white">View</span>
                    </div>
                  </b-link>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">{{lesson.title}}</h5>
                <!-- <i class="ml-2 text-mute">%{{lesson.progress}}</i> -->
                </div>
              <h6 class="text-muted">{{lesson.trainer}}</h6>
              <b-link @click="$emit('selectLesson',lesson)">
                <span v-if="lesson.passed===false">Try Again </span>
                <span v-else-if="lesson.passed===null">Answer Questions</span>
              </b-link>

              <h5 class="text-success" v-if="lesson.passed">PASSED</h5>
              <h5 class="text-danger" v-else-if="lesson.passed===false">FAILED</h5>

            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">{{lesson.length_in_minutes}} Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end" v-if="lesson.grade>=0">
                  <i class="text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Grade: {{lesson.grade}}</div>
                </b-col>
              </b-row>
            </div>
          </b-card>

</template>

<script>

export default {
    name: 'LessonCard',
    props: {
        value: {
        type: Object,
        required: true
        },
    },
    computed: {
        lesson: {
            set(value) {
                this.$emit('input', value)
            },
            get() {
                return this.value
            }
        }
    },
    methods:{
        bgInit() {
        return {
            '--cover-image': `${this.lesson.cover_image}`,
        }
        },
    },
}
</script>
