<template>
  <div v-if="course">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="shield"
            class="trans-background"
            src="@/assets/images/trans-banner.png"
        />
        <div class="container">
          <h1 class="text-white">{{course.title}}</h1>
          <h3 class="arrow-before text-white pl-30">
            {{course.subtitle}}<br/>
          </h3>
        </div>
      </div>
    </div>

    <Header :length="totalLength" />

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction :subtitle="course.subtitle" />
        </b-row>
      </b-container>
    </div>

    <StepsProgress
        :lessonProgress="course.lessons"
    />

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">


          <div v-for="(lesson, index) in course.lessons" :key="index">
            <div :class="{ completed: lesson.score>= lesson.passing_grade }"
                class="d-flex align-items-center page-num-container pt-80">
              <div class="page-num"></div>
              <h4 class="trans-section-title">{{lesson.title}}</h4>
            </div>
            <b-row class="trans-section-intro">
              <b-col lg="7">
                <p class="mb-lg-32">
                  {{lesson.subtitle}}
                </p>


                <b-row>
                  <b-col lg="6" >
                    
                          <LessonCard :value="lesson" @selectLesson="selectLesson" />

                  </b-col>
                </b-row>


              </b-col>
            </b-row>
          </div>

        </div>
      </b-container>
    </div>



    <div class="container">
      <Footer/>
    </div>

    <QuestionnaireModal
        v-model="selectedLesson"
        @showModal="show.questionnaireModal=$event"
        :showModalData="show.questionnaireModal"
        @refresh="updateLessonStatus"
    />

    <!-- <QuestionnaireResultModal
        v-model="selectedLesson"
        v-if="selectedLesson"
        @showModal="show.questionnaireResultModal=$event"
        :showModalData="show.questionnaireResultModal"
    /> -->

  </div>
</template>

<script>
import { http } from "@/services";

import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import Introduction from "./Introduction";
import Header from "./Header";
import StepsProgress from "./StepsProgress";
import LessonCard from "./cards/LessonCard";
import QuestionnaireModal from "./modals/QuestionnaireModal";
// import QuestionnaireResultModal from "./modals/QuestionnaireResultModal";


export default {
  name: "Course",
  components: {
    StepsProgress,
    Header,
    Introduction,
    Footer,
    LessonCard,
    QuestionnaireModal,
    // QuestionnaireResultModal,
  },
  created() {
    this.fetchUserLessonData();
  },
  data() {
    return {
      course: null,
      user_course_data: null,
      selectedLesson: null,
      show:{
        questionnaireModal: false,
        questionnaireResultModal: false
      },
    };
  },
  computed:{
    totalLength(){
      return this.course.lessons.reduce((acc, item) => acc + item.length_in_minutes, 0);
    },
  },
  methods: {
    updateLessonStatus(lesson){
      console.log('selectedLesson score', lesson.score);
      this.fetchUserLessonData();
      //this.show.questionnaireResultModal = true;
    },
    selectLesson(lesson){
      const clonedLesson = JSON.parse(JSON.stringify(lesson));
      clonedLesson.questions = this.shuffle(lesson.questions);
      if(lesson.questions.length>lesson.random_question_number){
        clonedLesson.questions = clonedLesson.questions.slice(0, lesson.random_question_number);
      }

      this.$set(this, 'selectedLesson', clonedLesson)

      // if(lesson.score<lesson.passing_grade){
      //   this.selectedLesson.questions.forEach(q=>{
      //     q.answer = null;
      //     delete q.answered_question;
      //   })
      //   console.log('this.selectedLesson', this.selectedLesson);
        this.show.questionnaireModal = true;
      // }
      // else{
      //   this.show.questionnaireResultModal = true;
      // }
    },
    shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1));
          x = a[i];
          a[i] = a[j];
          a[j] = x;
      }
    return a;
    },
    fetchUserLessonData() {
      http
        .get("user_lesson_data",{params:{course_id:this.$route.params.id}})
        .then((response) => {
          this.user_lesson_data = response.data;
          this.fetchCourse();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCourse(){
        http
          .get("course",{params:{id:this.$route.params.id}})
          .then((response) => {
            this.course = response.data;
            this.course.lessons.forEach(lesson=>{
              lesson.questions.forEach(q=>{
                this.$set(q, 'answer', null);
                q.choices = q.choices.map(c=>({label:c, value:c, code:c}));
              })

              this.$set(lesson, 'passed', null);

              const userLesson = this.user_lesson_data.find(l=>l.lesson===lesson.id);

              if(userLesson){
                this.$set(lesson, 'grade', userLesson.grade);
                this.$set(lesson, 'passed', userLesson.passed);
              }
              // this.calculateStatus(lesson);
            });
            console.log('this.course', this.course);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    calculateLessonStatus(lesson){
      let correct_count = 0;
      lesson.questions.forEach(q=>{
        q.answered_question = this.user_course_data.find(uq => uq.question == q.id && uq.answer)
        let value = null
        if(q.answered_question){
          value = q.answered_question.answer;
        }
       
        this.$set(q, 'answer', value)

        correct_count += q.correct_answers.includes(q.answer) ? 1 : 0

      })
      let lessonBase = lesson.questions.length > lesson.random_question_number ? lesson.random_question_number : lesson.questions.length;
      lesson.score = ((parseFloat(correct_count)/parseFloat(lessonBase))*100).toFixed(0);
   
    },
    // calculateStatus(lesson){
    //   let correct_count = 0;
    //   lesson.questions.forEach(q=>{
    //     q.answered_question = this.user_course_data.find(uq => uq.question == q.id && uq.answer)
    //     let value = null
    //     if(q.answered_question){
    //       value = q.answered_question.answer;
    //     }
       
    //     this.$set(q, 'answer', value)

    //     correct_count += q.answer===q.correct_answer? 1 : 0

    //   })
    //   let lessonBase = lesson.questions.length > lesson.random_question_number ? lesson.random_question_number : lesson.questions.length;
    //   lesson.score = ((parseFloat(correct_count)/parseFloat(lessonBase))*100).toFixed(0);
   
    // },
  },
};
</script>
