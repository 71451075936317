<template>
  <b-modal v-model="showModal" v-if="lesson"
           aria-label=""
           body-class="modal-form"
           modal-class="modal-style-one modal-background-white questionnaire"
           scrollable
           size="lg"
           :title="title"
           @hidden="clear"
           @ok="ok"
           no-close-on-backdrop
  >
     <QuestionBase v-for="(q, index) in lesson.questions" :key="index"
      :errors="errors['q'+q.id]"
        :question="q"
        :valid="!!q.answer">

      <InputRadioBasic v-if="q.question_type=='Radio'" 
        :value="q.answer"
        :key="'question'+q.id"
        :args="inputArgs"
        :options="q.choices"
        @input="setAnswer($event, index)"
        class="radio-options-wide" 
      />

      <InputSelectBasic v-if="q.question_type=='Dropdown'"
        :value="q.answer"
        :key="'question'+q.id"
        :args="inputArgs"
        :options="q.choices"
        :placeholder="'Please select'"
        @input="setAnswer($event, index)"
      />

    </QuestionBase>


   
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button
            class="btn btn-backwards white-border"
            @click="cancel">Cancel
        </button>
        <button
            :disabled="disabled"
            class="btn btn-forwards"
            @click="ok">Submit
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {clone} from "@/mixins/clone";
import {http} from "@/services";
import {toast} from '@/mixins/toast'

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadioBasic from "@/components/common/other/inputs/InputRadioBasic";
import InputSelectBasic from "@/components/common/other/inputs/InputSelectBasic";

export default {
  name: 'QuestionnaireModal',
  components: {
    InputRadioBasic,
    InputSelectBasic,
    QuestionBase
  },
  mixins: [
    clone,
    toast
  ],
  props: {
    showModalData: {
      type: Boolean,
      required: false
    },
    value: {
      type: Object,
      required: false
    },
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit('showModal',
            value)
      },
      get() {
        return this.showModalData
      }
    },
    lesson: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        if(this.value){
          this.value.questions.forEach(q=>{
            this.$set(this.errors, 'q'+q.id, []);
          })
        }
        return this.value
      }
    },
    title() {
      return 'Exam'
    },
    user() {
      return this.$store.getters.user
    },
    disabled() {
      return false
    },
  },
  data() {
    return {
      errors: {},
      inputArgs: {placeholder:'Please select...'},
    }
  },
  methods: {
    setAnswer(args, index){
      this.lesson.questions[index].answer = args
    },
    ok(bvEvent) {
      bvEvent.preventDefault()

      let error_flag = false;
      const promises = this.lesson.questions.map((q)=>{
        let answer_data = { question: q.id, answer: q.answer};
        return http
          .post("user_course_data",answer_data)
          .catch((error) => {
            error_flag = true;
            this.handleErrors(q.id,error.response.data)
          });
      });

      Promise.all(promises).then(() => {
        if(!error_flag){
          this.clearErrors()
          this.showModal = false
          const grade = this.calculateScore();
          const passed = grade >= this.lesson.passing_grade ? true : false;
          let lesson_data = { lesson: this.lesson.id, grade: grade, passed: passed };

          http
            .post("user_lesson_data",lesson_data)
            .then(() => {

              this.$emit('refresh',this.lesson) // refresh

              if(passed){
                this.message("You passed :)","PASS","success");
              }
              else{
                this.message("You failed :(","FAILED","danger");
              }

            })
            .catch((error) => {
              console.log(error);
            });

        }
      });

    },
    calculateScore(){
      let correct_count = 0;
      this.lesson.questions.forEach(q=>{

        correct_count += q.correct_answers.includes(q.answer) ? 1 : 0

      })
      return ((parseFloat(correct_count)/parseFloat(this.lesson.questions.length))*100).toFixed(0);
    },
    async message(message, title = 'PASS', variant = 'success') {

      return this.$bvModal.msgBoxOk(message, {
        title: title,
        size: 'md',
        buttonSize: 'sm',
        okVariant: variant,
        footerClass: 'p-2',
        modalClass: 'modal-style-one',
        centered: true
      })
      .then(value => {
        return value
      })
    },
    handleErrors(id, errors) {
      // assign errors to inputs
      let keys = Object.keys(errors);

      for (let i in keys) {
        this.errors['q'+id] = errors[keys[i]]
      }
    },
    clearErrors() {
      let keys = Object.keys(this.errors);
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    clear() {
      this.clearErrors()
    },
  }
}

</script>
